import { createApp } from 'vue'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'


import App from './App.vue'
import router from './router/index.js'
import main_css from './assets/css/main.css'


const app = createApp(App);
app.use(router);
app.use(ElementPlus);

app.use(main_css);
app.mount('#app');
