import { createRouter, createWebHistory } from 'vue-router';
import globalState from '@/store/globalState';

const routes = [
    {
        path: '/',
        name: 'Home',
        // 起始畫面
        component: ()   => import('@/views/Home.vue')
    },
    {
        path: '/preparing',
        name: 'Preparing',
        // 正在設定您的頁面 (1)
        component: () => import('@/views/Preparing.vue')
    },
    {
        path: '/payment',
        name: 'Payment',
        // 請選擇支付方式 (3)
        component: () => import('@/views/Payment.vue')

    },
    {
        path: '/transaction',
        name: 'Transaction',
        // 交易頁面 (4)
        component: () => import('@/views/Transaction.vue')
    },
    {
        path: '/payment-failed',
        name: 'PaymentFailed',
        // 支付失敗頁面(9)
        component: () => import('@/views/PaymentFailed.vue')
    },
    {
        path: '/charge',
        name: 'Charge',
        // 充電中頁面 (8)
        component: () => import('@/views/Charge.vue')
    },
    {
        path: '/ready',
        name: 'Ready',
        // 支付成功(6)
        component: () => import('@/views/Ready.vue')
    },
    {
        path: '/waiting',
        name: 'Waiting',
        // 等待授權結果 (5)
        component: () => import('@/views/Waiting.vue')
    },
    {
        path: '/login',
        name: 'Login',
        // 登入頁面(2)
        component: () => import('@/views/Login.vue')
    },
    {
        path: '/verify',
        name: 'Verify',
        // 本車位充電中(7)
        component: () => import('@/views/Verify.vue')
    },
    {
        path: '/success',
        name: 'Success',
        // 充電完成(10)
        component: () => import('@/views/Success.vue')

    }
   
] 
const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    document.title = '銓泰充電樁系統'
    globalState.isLoading = true;
    next();
});

router.afterEach(() => {
    globalState.isLoading = false;
});

export default router